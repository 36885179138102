.emptyBlock {
  padding: 6rem 0;
}
.description {
  font-size: 2rem;
  line-height: 140%;
  max-width: 40.4rem;
}
.button {
  padding: 1.6rem 7rem;
}
@media (max-width: 620px) {
  .description {
    font-size: 1.6rem;
    line-height: 140%;
    max-width: 40.4rem;
  }
  .button {
    padding: 1.6rem 7rem;
  }
}