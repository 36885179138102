/*DISPLAY*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700&display=swap');

.d-b {
    display: block;
}

.d-f {
    display: flex;
}

.d-n {
    display: none;
}
.w-content {
    max-width: fit-content;
}
.h-content {
    max-height: fit-content;
}
.al-self-center {
    align-self: center;
}
.ale-self-end {
    align-self: flex-end;
}
/*POSITIONS*/

.top-0 {
    top: 0;
}

.left-0 {
    left: 0;
}

.right-0 {
    right: 0;
}

.bottom-0 {
    bottom: 0;
}

.p-abs {
    position: absolute;
}

.p-rel {
    position: relative;
}

.p-fix {
    position: fixed;
}

.p-stick {
    position: sticky;
}

/*JUSTIFY CONTENT*/
.jc-center {
    justify-content: center;
}

.jc-start {
    justify-content: flex-start;
}

.jc-end {
    justify-content: flex-end;
}

.jc-between {
    justify-content: space-between;
}

.jc-around {
    justify-content: space-around;
}

.jc-evenly {
    justify-content: space-evenly;
}

/*ALING*/
.al-center {
    align-items: center;
}

.al-start {
    align-items: flex-start;
}

.al-end {
    align-items: flex-end;
}

/*ALIGN SELF*/
.self-start {
    align-self: flex-start;
}

.self-end {
    align-self: flex-end;
}

.self-center {
    align-self: center;
}

/*FLEX-DIRECTIONS*/
.fd-row {
    flex-direction: row;
}

.fd-reverse-row {
    flex-direction: row-reverse;
}

.fd-column {
    flex-direction: column;
}

.fd-reverse-row {
    flex-direction: column-reverse;
}

/*MARGINS*/
.mg-auto {
    margin: auto;
}

.ml-auto {
    margin: 0 auto;
}
.mg-0 {
    margin: 0;
}

.mg-10 {
    margin: 10px;
}
.mg-15 {
    margin: 15px;
}
.mg-20 {
    margin: 20px;
}
.mg-25 {
    margin: 25px;
}
.mg-30 {
    margin: 30px;
}

.mg-40 {
    margin: 40px;
}

.mg-50 {
    margin: 50px;
}

.mg-10-0 {
    margin: 10px 0;
}
.mg-15-0 {
    margin: 15px 0;
}


.mg-0-10 {
    margin: 0 10px;
}

.mg-0-15 {
    margin: 0 15px;
}

.mg-20-0 {
    margin: 20px 0;
}

.mg-0-20 {
    margin: 0 20px;
}

.mg-30-0 {
    margin: 30px 0;
}

.mg-0-30 {
    margin: 0 30px;
}

.mg-40-0 {
    margin: 40px 0;
}

.mg-0-40 {
    margin: 0 40px;
}

.mg-50-0 {
    margin: 50px 0;
}

.mg-0-50 {
    margin: 0 50px;
}

/*PADDINGS*/
.pd-0 {
    padding: 0;
}

.pd-10 {
    padding: 10px;
}
.pd-15 {
    padding: 15px;
}
.pd-20 {
    padding: 20px;
}

.pd-30 {
    padding: 30px;
}

.pd-40 {
    padding: 40px;
}

.pd-50 {
    padding: 50px;
}

.pd-10-0 {
    padding: 10px 0;
}

.pd-10-0 {
    padding: 15px 0;
}

.pd-0-10 {
    padding: 0 10px;
}

.pd-0-10 {
    padding: 0 15px;
}
.pd-20-0 {
    padding: 20px 0;
}

.pd-0-20 {
    padding: 0 20px;
}

.pd-30-0 {
    padding: 30px 0;
}

.pd-0-30 {
    padding: 0 30px;
}

.pd-40-0 {
    padding: 40px 0;
}

.pd-0-40 {
    padding: 0 40px;
}

.pd-50-0 {
    padding: 50px 0;
}

.pd-0-50 {
    padding: 0 50px;
}

/*FLEXES*/

.f-025 {
    flex: 0.25;
}

.f-03 {
    flex: 0.3;
}

.f-035 {
    flex: 0.35;
}

.f-04 {
    flex: 0.4;
}

.f-045 {
    flex: 0.45;
}

.f-05 {
    flex: 0.5;
}

.f-055 {
    flex: 0.55;
}

.f-06 {
    flex: 0.6;
}

.f-065 {
    flex: 0.65;
}

.f-07 {
    flex: 0.7;
}

.f-075 {
    flex: 0.75;
}

.f-08 {
    flex: 0.8;
}

.f-085 {
    flex: 0.85;
}

.f-09 {
    flex: 0.9;
}

.f-095 {
    flex: 0.95;
}

.f-1 {
    flex: 1;
}

/*MIXES*/
.f-c-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.f-c-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.f-column {
    display: flex;
    flex-direction: column;
}

.f-column-betw {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.f-row-betw {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.flex-wrap {
    display: flex;
    flex-wrap: wrap;
}

/*WIDTH*/
.w-100p {
    width: 100%;
}

.w-100v {
    width: 100vw;
}

/*HEIGTH*/
.h-100p {
    height: 100%;
}

.h-100v {
    height: 100vh;
}

/*TEXT-ALIGN*/
.txt-right {
    text-align: right;
}

.txt-center {
    text-align: center;
}

/*TRANSITIONS*/

.hidden {
    opacity: 0;
    visibility: hidden;
}
.visible {
    opacity: 1;
    visibility: visible;
}
.visible-unset-transform {
    opacity: 1;
    visibility: visible;
    transform: unset;
}

.t-opacity-visible-3 {
    transition: .3s opacity, .3s visibility;
}

.t-opacity-visible-4 {
    transition: 0.4s opacity, 0.4s visibility;
}

.t-opacity-visible-5 {
    transition: 0.5s opacity, 0.5s visibility;
}

.t-opacity-visible-6 {
    transition: 0.6s opacity, 0.6s visibility;
}

.t-opacity-visible-7 {
    transition: 0.7s opacity, 0.7s visibility;
}

.t-opacity-visible-8 {
    transition: 0.8s opacity, 0.8s visibility;
}

.t-opacity-visible-9 {
    transition: 0.9s opacity, 0.9s visibility;
}

.t-opacity-visible-10 {
    transition: 1s opacity, 1s visibility;
}
.t-opacity-visible-transform-3 {
    transition: 0.3s opacity, 0.3s visibility, 0.3s transform;
}

.t-opacity-visible-transform-4 {
    transition: 0.4s opacity, 0.4s visibility, 0.4s transform;
}

.t-opacity-visible-transform-5 {
    transition: 0.5s opacity, 0.5s visibility, 0.5s transform;
}

.t-opacity-visible-transform-6 {
    transition: 0.6s opacity, 0.6s visibility, 0.6s transform;
}

.t-opacity-visible-transform-7 {
    transition: 0.7s opacity, 0.7s visibility, 0.7s transform;
}

.t-opacity-visible-transform-8 {
    transition: 0.8s opacity, 0.8s visibility, 0.8s transform;
}

.t-opacity-visible-transform-9 {
    transition: 0.9s opacity, 0.9s visibility, 0.9s transform;
}

.t-opacity-visible-transform-10 {
    transition: 1s opacity, 1s visibility, 1s transform;
}

.t-transform-3 {
    transition: 0.3s transform;
}

.t-transform-4 {
    transition: 0.4s transform;
}

.t-transform-5 {
    transition: 0.5s transform;
}

.t-transform-6 {
    transition: 0.6s transform;
}

.t-transform-7 {
    transition: 0.7s transform;
}

.t-transform-8 {
    transition: 0.8s transform;
}

.t-transform-9 {
    transition: 0.9s transform;
}

.t-transform-10 {
    transition: 1s transform;
}


/*GAPS*/
.gap-5 {
    gap: 5px;
}

.gap-10 {
    gap: 10px;
}

.gap-15 {
    gap: 15px;
}

.gap-20 {
    gap: 20px;
}
.gap-25 {
    gap: 25px;
}
.gap-30 {
    gap: 30px;
}
.gap-35 {
    gap: 35px;
}
.gap-40 {
    gap: 40px;
}
.gap-45 {
    gap: 45px;
}
.gap-50 {
    gap: 50px;
}
.gap-60 {
    gap: 60px;
}
.gap-70 {
    gap: 70px;
}
.gap-80 {
    gap: 80px;
}

.ellipsis-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.ellipsis-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.ellipsis-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ellipsis-4 {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.ellipsis-5 {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ellipsis-6 {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ellipsis-7 {
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

/*OVERFLOW*/
.of-hide {
    overflow: hidden;
}

.of-scroll {
    overflow: scroll;
}

.of-y-hide {
    overflow-y: hidden;
}

.of-y-scroll {
    overflow-y: scroll;
}

.of-x-hide {
    overflow-x: hidden;
}

.of-x-scroll {
    overflow-x: scroll;
}

/*CURSORS*/
.cur-pointer {
    cursor: pointer;
}

/*LIST STYLES*/
.marked {
    list-style: disc;
}

/*COLOR*/
.c-white {
    color: white;
}

.c-black {
    color: black !important;
}
.c-lightgray {
    color: lightgrey;
}
.text-nowrap {
    text-wrap: nowrap;
}
/*FONT-WEIGHT*/
.fw-1 {
    font-weight: 100;
}

.fw-2 {
    font-weight: 200;
}

.fw-3 {
    font-weight: 300;
}

.fw-4 {
    font-weight: 400;
}

.fw-5 {
    font-weight: 500;
}

.fw-6 {
    font-weight: 600;
}

.fw-7 {
    font-weight: 700;
}

.fw-8 {
    font-weight: 800;
}

.fw-9 {
    font-weight: 900;
}

/*BACKGROUND*/
.bg-cover {
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-white {
    background: white;
}

.bg-black {
    background: black;
}

/*SCROLLBARS*/

.scrollbar-unset::-webkit-scrollbar {
    display: none;
}



/*BASE SETTINGS*/
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    left: 0;
    top: 0;
}

a {
    text-transform: unset;
    text-decoration: unset;
    width: fit-content;
    color: unset;
}

li {
    list-style: none;
}

input, button, select, textarea {
    border: unset;
    outline: none;
}

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
