.product {

  h3 {
    font-size: 2.4rem;
  }
  p, .weight{
    font-size: 1.6rem;
  }
  .weight{
    min-width: 4.6rem;
    text-wrap: nowrap;
  }
  .count {
    font-size: 2.4rem;
    min-width: 3rem;
    text-align: center;
  }
 
  .img {
    border-radius: 6px;
    background-size: contain!important;
    background-repeat: no-repeat;
    height: 23.3rem;
    background-position: center;
    overflow: hidden;
    position: relative;
    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%
    }
    .productLabel {
      position: absolute;
      border-radius: 5rem;
      padding: .5rem 1rem;

      top: 1rem;
      left: 1rem;
      b {
        color: white;
        font-size: 1.6rem;
      }
    }
  }
  h4 {
    font-size: 2.3rem;
  }
  min-height: 37.7rem;
  width: 31rem;
  .btn {
    font-size: 2rem;
    font-family: "TT Chocolates", sans-serif;
    padding: .6rem 2rem;

  }
}
@media (max-width: 1100px) {
  .product {
    flex-direction: row;
    min-height: unset;
    height: fit-content;
    width: 100%;
    .img {
     width: 24rem;
      height: 18rem;
    }
  }
  .textBlock {
    flex: unset;
  }
}
@media (max-width: 820px) {
  .main {
    .block {
      .restaurants {
        .search {
          width: 100%;
        }
        flex-direction: column;
        .orderTrigger {
          padding: .5rem;
        }
      }
    }

  }
  .product {
    flex-direction: row;
    min-height: unset;
    height: fit-content;

    width: 100%;
    .img {
      width: 18rem;
      height: 12rem;
      .productLabel {
        padding: .4rem .8rem;
        right: .6rem;
        svg {
          height: .9rem;
        }
        top: .6rem;
        left: unset;
        b {
          color: white;
          font-size: 1.1rem;
          font-weight: 500;
        }
      }
    }
  }
}
@media (max-width: 480px) {
  .product {
    .content {
      gap: 0rem;
    }
    h3 {
      font-size: 1.8rem;
    }
    p {
      font-size: 1.2rem;
    }
    .weight{
      font-size: 1.6rem;
    }

    .count {
      font-size: 2.4rem;
      min-width: 3rem;
      text-align: center;
    }
    .sale {
      margin-top: .6rem;
      b {
        font-size: 1.2rem;
      }
      .line {
        width: calc(100% + .5rem);
        border-radius: 2px;
        top: 50%;
        transform: rotateZ(-15deg) translateY(-50%) translateX(30%);
        height: 1px;
        left: -50%;
        background: #FF2000;
      }
    }
    .img {

      height: 8rem;
      width: 11rem;
      .productLabel {
        padding: .2rem .5rem;
        right: 0;
        svg {
          height: .8rem;
        }
        top: 0;
        b {
          color: white;
          font-size: 1.1rem;
          font-weight: 500;
        }
      }
    }
    .btn {
      font-size: 1.6rem;
      font-family: "TT Chocolates", sans-serif;
      padding: .3rem 2.6rem;

    }
  }
}