
.backButton {
  height: 4.5rem;
  padding: 1.5rem !important;
  margin-top: 1.5rem;
  font-size: 1.4rem !important;
  max-width: fit-content;
}
.restaurantsSection {
  .sideWrapper {
    height: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: #c7c7c7;
    }
    width: 34rem;
  
    .itemWrapper {
      padding-top: .4rem;
      padding-left: .4rem;
      padding-bottom: .4rem;
      width: 100%;
      cursor: pointer;
      .item {
        border-radius: 6px 0px 0px 6px;
        padding: 1.1rem 1.6rem;

        transition: .3s background;
        b {
          font-size: 1.6rem;
        }
        p {
          font-size: 1.2rem;
        }
        .bottomText {
          font-size: 1.4rem;
        }
      }
    }
  }
  .map {
    height: 100%;
  }
  height: 80vh;
  border-radius: 6px;
  max-height: 576px;
}
.sliderArrowWrapper {
  z-index: 6;
  > div {
    background: white;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
  }
}
.sliderArrowWrapperLeft {
  left: -1.5rem;
}
.sliderArrowWrapperRight {
  right: -1.5rem;
}
.choosenRestaruantBlock {

  .addressTitle {
    font-size: 2rem;
  }
  .addressAreaText {
    font-size: 1.4rem;
  }
  .gallery {
    overflow-x: hidden;
    .item {
      background-position: center;
      min-width: 18rem;
      min-height: 11rem;
      border-radius: 6px;
    }
  }
  .workTimeBlock {

  }
  .phoneLabel {
    font-size: 1.4rem;
  }
  .phone {
    font-size: 1.8rem;
    font-weight: 700;
  }
  .workClocks {
    p {
      font-size: 1.2rem;
    }
    b {
      font-size: 1.6rem;
    }
  }
}
@media (max-width: 1100px) {
  .choosenRestWrapper {
    width: 100%;
    max-width: unset;
  }
  .choosenMapWrapper {
    max-width: unset;
    padding: unset;
  }
  .restaurantsSection {
    max-height: unset;
    flex-direction: column-reverse;
    height: unset;
    .sideWrapper {
      height: 100%;
      overflow-y: unset;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background: #c7c7c7;
      }
      width: 100%;
      border: unset;
      .itemWrapper {
        padding-top: .4rem;
        padding-left: .4rem;
        padding-bottom: .4rem;
        width: 100%;

        cursor: pointer;
        .item {
          border-radius: 6px 0px 0px 6px;
          padding: 1.1rem 1.6rem;
          &:hover {
            background: #F3F3F7;
          }
          transition: .3s background;
          b {
            font-size: 1.6rem;
          }
          p {
            font-size: 1.2rem;
          }
          .bottomText {
            font-size: 1.4rem;
          }
        }
      }
    }
    .map {
      min-height: 45vh;
      height: 45vh;
    }

  }

}