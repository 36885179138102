.promo {
  background: #fafafa;

  .promos {
    .container {
      .item {
        overflow: hidden;
        background: white;
        width: 32rem;
        min-height: 18.3rem;
        border-radius: 4px;
        border: 1px solid #fafafa;
        transition: 0.3s border;
        .image {
          height: 11rem;
          background-position: center;
        }
        .info {
          h3 {
            font-size: 2rem;
          }
          p {
            font-size: 1.4rem;
          }
          svg {
            transition: 0.3s transform;
            path {
              transition: 0.3s fill;
            }
          }
        }
        &:hover {
          border: 1px solid #fcc8a5;
          svg {
            transform: translateX(5px);
            path {
              fill: #fcc8a5;
            }
          }
        }
      }
    }
    .arrowWrapper {
      visibility: hidden;
      background: rebeccapurple;
      .arrow {
        height: 4rem;
        width: 4rem;
        background: white;
        border-radius: 50%;
      }
    }
  }
}
.main {
  margin-top: 1.5rem;
}
.backButton {
  height: 4.5rem;
  padding: 1.5rem !important;
  font-size: 1.4rem !important;
  max-width: fit-content;
}
.restaurantsSection {
  .sideWrapper {
    height: 100%;
    overflow-y: auto;
    padding: 0;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: #c7c7c7;
    }
    min-width: 34rem;
    .itemWrapper {
      padding-top: 0.4rem;
      padding-left: 0.4rem;
      padding-bottom: 0.4rem;
      width: 100%;
      
      cursor: pointer;
      .item {
        border-radius: 6px 0px 0px 6px;
        padding: 1.1rem 1.6rem;
        transition: 0.3s background;
        b {
          font-size: 1.6rem;
        }
        p {
          font-size: 1.2rem;
        }
        .bottomText {
          font-size: 1.4rem;
        }
      }
    }
  }
  .map {
  }
  height: 80vh;
  border-radius: 6px;
  max-height: 576px;
}
.sliderArrowWrapper {
  z-index: 6;
  > div {
    background: white;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
  }
}
.sliderArrowWrapperLeft {
  left: -1.5rem;
}
.sliderArrowWrapperRight {
  right: -1.5rem;
}
.choosenRestaruantBlock {
  color: #c3c3c3;

  .addressTitle {
    font-size: 2rem;
  }
  .addressAreaText {
    font-size: 1.4rem;
  }
  .gallery {
    overflow-x: hidden;
    .item {
      background-position: center;
      min-width: 18rem;
      min-height: 11rem;
      border-radius: 6px;
    }
  }
  .workTimeBlock {
  }
  .phoneLabel {
    font-size: 1.4rem;
    color: #c3c3c3;
  }
  .phone {
    font-size: 1.8rem;
    font-weight: 700;
  }
  .workClocks {
    p {
      font-size: 1.2rem;
    }
    b {
      font-size: 1.6rem;
    }
  }
}
@media (max-width: 1100px) {
  .restaurantsMap {
    max-width: unset !important;
  }
  .restContainer {
    max-width: 100% !important;
    padding: unset;
  }

  .map {
    height: 50vh;
    border-radius: 6px;
    max-height: 576px;
  }
  .restaurantsSection {
    background: #fafafa;
    height: unset;
    max-height: unset;
    .restaurantsContainer {
      width: 100%;
      padding: 0 2rem;
    }
    .itemWrapper {
      border-radius: 4px;
      padding: 0.4rem;
      max-width: 100%;
      border-bottom: 2px solid #fafafa;
      cursor: pointer;
      .item {
        border-radius: 0.6rem !important;
      }
    }

    .mapContainer {
      min-height: 30vh;
    }

    .sideWrapper {
      width: 100%;
      padding: unset !important;
      height: unset;
      max-height: unset;
      overflow-y: unset;
      padding: 0 2rem;
      min-width: unset;
    }

    .map {
      flex: 1;
      flex: unset;
      width: 100%;
    }

    flex-direction: column-reverse;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .mapContainer {
    min-height: 48vh !important;
  }
  .restaurantsSection {
    .sideWrapper {
      border-left: unset;
      border-bottom: unset;
    }
    .restaurantsContainer {
      padding: 0 !important;
    }
  }
}
