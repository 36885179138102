.button {
  border-radius: 4px;

  font-weight: 400;
  transition: .3s background;
  padding: .8rem 2.7rem;
  cursor: pointer;
  font-size: 2rem;

}
