.successWindow {
  z-index: 12;
  opacity: 0;
  visibility: hidden;
  text-align: center;
  .container {
    max-width: 42rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    width: 100%;
  }
  h1 {
    font-size: 3.6rem;
  }
}

@media (max-width: 500px) {
  .successWindow {
    .container {
      max-width: 90vw;
      justify-content: space-between;
    }
  }
}
.successWindowOpened {
  opacity: 1;
  visibility: visible;
}