.button {
  border-radius: 4px;
  font-weight: 700;
  transition: .3s filter;
  cursor: pointer;
  font-size: 2rem;

  &:hover {
    filter: brightness(95%);
  }
  &:disabled {
    background: #E2E2E9 !important;
    filter:grayscale(100%);
    color: #ABADBA;
  }
}