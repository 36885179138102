.radio {
  cursor: pointer;
  gap: 7px;

  .marker {
    min-height: 1.84rem;
    min-width: 1.84rem;
    max-height: 1.84rem;
    max-width: 1.84rem;
    border-radius: 50%;
    border: 2px solid #4D4D4D;
    transition: .3s border, .3s border-stroke;

  }

  .selectedCircle {
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
  }

  .text {
    font-size: 1.8rem;
    color: #c3c3c3;
  }
}
