.search {

  transition: .3s background, .3s box-shadow, .3s border;
  input {
    font-size: 1.4rem;
  }
  input::placeholder {
    color: #A9A9A9;
  }

}
.searchFocused {
  border: 1px solid transparent;
  box-shadow: 0px 1px 8px 0px rgba(56, 56, 56, 0.10);
}