.window {
  max-height: 87.5rem
}
@media (max-width: 800px) {
  .bookingForm {
    overflow-y: scroll;
    flex: 1;
  }
  .bookingForm::-webkit-scrollbar {
    display: none;
  }
}

.inputSelect {
  max-height: 16rem;
  overflow-y: auto;
}
@media (max-width: 500px) {
  .window {
    height: 100vh;
    width: 100vw;
    max-width: unset;
    max-height: unset;
    border-radius: unset;
  }
}