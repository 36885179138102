.block {
  border-radius: 4px;
  border: 1px solid #E2E2E9;
  font-size: 1.4rem;
  transition: .3s border;
  label {
    transition: .3s color;
  }
  &:hover {
    border: 1px solid #FB634D;
  }
}

.labelItem {
  font-size: 1.4rem;
}
.errorTextColor {
  color: #ff6328ff;
}
.close {
  min-height: 100%;
}