.slide-in-bottom-cookie {
    -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-10-8 13:31:16
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
    0% {
        bottom: -20rem;
        opacity: 0;
    }
    100% {
        bottom: 1rem;
        opacity: 1;
    }
}
.infiniteSpin {
    animation: spin .5s linear infinite;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes slide-in-bottom {
    0% {
        bottom: -20rem;
        opacity: 0;
    }
    100% {
        bottom: 1rem;
        opacity: 1;
    }
}
